/**
 *
 * @param {ng.IExceptionHandlerService} $delegate
 * @param {ng.auto.IInjectorService} $injector
 * @returns {(error: Error, cause: string) => void}
 */
export default function exceptionHandlerDecorator($delegate, $injector) {
  'ngInject'

  return function (exception, cause) {
    /** @type {import('../blocks/gtag').AnalyticsService} Inject dependencies manually to prevent circular dependencies */
    const analytics = $injector.get('analytics')
    $delegate(exception, cause)
    analytics.sendException(exception, cause)
  }
}
