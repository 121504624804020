import angular from 'angular'
import app from './'
import core from './core'

/**
 * Manually boostrap angularJS app after loading configuration from server
 */
angular.element(function () {
  window
    .fetch('config.json', { credentials: 'include' })
    .then((resp) => resp.json())
    .then(onConfigCompleted)
    .catch(onConfigFailed)
})

/**
 *
 * @param {import('./core').Config} config
 */
function onConfigCompleted(config) {
  console.debug('Retrieved app configuration from server', config)
  angular.module(core).constant('config', config)
  angular.bootstrap(document, [app], { strictDi: true })
}

/**
 * @param {any} error
 */
function onConfigFailed(error) {
  console.error('Could not load application', error.message)
}
