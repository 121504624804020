/** @type {ng.IDirectiveFactory} */
export default function equalsDirective() {
  'ngInject'

  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, elem, attrs, controller) {
      const ngModel = /** @type {ng.INgModelController} */ (controller)

      ngModel.$validators.equals = function (modelValue, viewValue) {
        var pass1 = modelValue || viewValue
        var pass2 = attrs.elEquals
        return pass1 === pass2
      }

      // observe the other value and re-validate on change
      attrs.$observe('elEquals', () => ngModel.$validate())
    }
  }
}
