import angular from 'angular'
import analyticsDirective from './analytics.directive'
import AnalyticsService from './analytics.service.js'
import gtagRunBlock from './gtag.config'
import GtagWrapper from './gtag.service'

export default angular
  .module('blocks.gtag', [])
  .run(gtagRunBlock)
  .directive('miAnalyticsOn', analyticsDirective)
  .service('analytics', AnalyticsService)
  .service('gtag', GtagWrapper)
