/**
 *
 * @param {import('@uirouter/angularjs').StateProvider} $stateProvider
 */
export default function loginRouteConfigBlock($stateProvider) {
  'ngInject'

  $stateProvider
    .state({
      url: '/?redirect_url&error&omniauth_callback_url',
      name: 'login',
      component: 'elLogin',
      resolve: { redirectUrl: resolveRedirectUrl, error: resolveError, omniauthCallbackUrl: resolveOmniauthCallbackUrl }
    })
    .state({
      url: '/logout',
      name: 'logout',
      onEnter: logout
    })
}

/**
 *
 * @param {import('@uirouter/angularjs').Transition} $transition$
 * @returns
 */
function resolveRedirectUrl($transition$) {
  'ngInject'
  return $transition$.params().redirect_url
}

/**
 *
 * @param {import('../core').ConnectionService} connection
 * @param {import('@uirouter/angularjs').StateService} $state
 * @returns
 */
function logout(connection, $state) {
  'ngInject'
  return connection.disconnect().then(() => $state.go('login'))
}

/**
 *
 * @param {import('@uirouter/angularjs').Transition} $transition$
 * @returns
 */
function resolveError($transition$) {
  'ngInject'
  return $transition$.params().error
}

/**
 *
 * @param {import('@uirouter/angularjs').Transition} $transition$
 * @returns
 */
function resolveOmniauthCallbackUrl($transition$) {
  'ngInject'
  return $transition$.params().omniauth_callback_url
}
