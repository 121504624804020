/**
 * @param {import('@uirouter/angularjs').UrlService} $urlServiceProvider
 */
export function routeConfigBlock($urlServiceProvider) {
  'ngInject'
  // Redirect user to root if trying to access an url which does not exist
  $urlServiceProvider.rules.otherwise('/')
}

/**
 * @param {ng.IRootScopeService} $rootScope
 * @param {import('@uirouter/angularjs').StateService} $state
 * @param {import('@uirouter/angularjs').TransitionService} $transitions
 * @param {ng.IExceptionHandlerService} $exceptionHandler
 * @param {import('../blocks/gtag').AnalyticsService} analytics
 * @param {ng.ITimeoutService} $timeout
 * @param {ng.ILogService} $log
 */
export function routeRunBlock($rootScope, $state, $transitions, $exceptionHandler, analytics, $timeout, $log) {
  'ngInject'

  // Global transition hooks
  $transitions.onStart({}, onTransitionStart)
  $transitions.onSuccess({}, onTransitionSuccess)
  $transitions.onError({}, onTransitionError)
  $state.defaultErrorHandler(handleRejectedTransition)

  /**
   * Log transition target state.
   * Enable global loading state
   * @param {import('@uirouter/angularjs').Transition} transition
   */
  function onTransitionStart(transition) {
    $log.info(`[Route] entering ${transition.$to().name} (from ${transition.$from().name || 'root'})`)
    toggleLoadingIndicator(true)
  }

  /**
   * Disable global loader.
   * Register current state in root scope.
   * Register state in google analytics
   * @param {import('@uirouter/angularjs').Transition} transition
   */
  function onTransitionSuccess(transition) {
    toggleLoadingIndicator(false)
    $rootScope.currentState = transition.$to()
    // Used timeout to wait next digest cycle to ensure page title changed correctly
    $timeout(analytics.sendPageview.bind(analytics))
  }

  /**
   * Disable global loader.
   * Redirect to home
   * @param {import('@uirouter/angularjs').Transition} trans
   */
  function onTransitionError(trans) {
    toggleLoadingIndicator(false)
    // Add the transition detail to rootscope so we can use it on our error handler
    $rootScope.lastFailedTransition = `Transition from ${trans.$from().name} to -> ${trans.$to().name}`
  }

  /**
   * Executes when current transitions succeeded or errored
   * @param {boolean} enabled
   */
  function toggleLoadingIndicator(enabled) {
    $rootScope.loadingState = enabled
  }

  /**
   * Handle transition rejections by passing them to the angularJS $exceptionHandler
   * Please note that rejection due to redirection or aborted transition will NOT be passed to this function
   * @param {import('@uirouter/angularjs').Rejection} rejection
   */
  function handleRejectedTransition(rejection) {
    // Convert rejection into an error message
    const exception = new Error(`${rejection.message}: ${JSON.stringify(rejection.detail)}`)
    $exceptionHandler(exception, $rootScope.lastFailedTransition)
    $state.go('login')
  }
}
