/**
 * @type {ng.IDirectiveFactory}
 * @param {import('../core').UserService} userService
 */
export default function passwordPolicyDirective(userService) {
  'ngInject'

  return {
    restrict: 'A',
    require: 'ngModel',
    link(scope, elem, attrs, controller) {
      const ngModel = /** @type {ng.INgModelController} */ (controller)
      ngModel.$validators.policy = (modelValue, viewValue) => userService.isPasswordValid(modelValue || viewValue)
    }
  }
}
