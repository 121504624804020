import coreModule from './core.module'
export default coreModule.name

/**
 * @typedef Config
 * @property {string} google_captcha_sitekey
 * @property {string} maxics_url
 * @property {string} graphics_url
 * @property {string} user_api_endpoint
 * @property {string} google_analytics_tracking_id
 * @property {string} jwt_cookie_name
 * @property {string} app_theme
 */

/** @typedef {import('./user.service').default} UserService */
/** @typedef {import('./connection.service').default} ConnectionService */
