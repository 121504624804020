import { Errors } from './login.constants'
import template from './login.html'

export default {
  template,
  bindings: { redirectUrl: '<?', error: '@?', omniauthCallbackUrl: '<?' },
  get controller() {
    return LoginController
  }
}

/**
 * @constructor
 * @param {ng.IWindowService} $window
 * @param {ng.material.IToastService} $mdToast
 * @param {ng.IQService} $q
 * @param {*} analytics
 * @param {*} config
 * @param {import('../core/connection.service').default} connection
 */
export class LoginController {
  /** @type {string | undefined} */
  // @ts-ignore Initialized by component bindings
  error
  /** @type {string | undefined} */
  // @ts-ignore Initialized by component bindings
  redirectUrl
  /** @type {string | undefined} */
  // @ts-ignore Initialized by component bindings
  omniauthCallbackUrl
  /** @type {ng.IFormController} */
  // @ts-ignore Bounded from template at startup
  loginForm

  /**
   *
   * @param {ng.IWindowService} $window
   * @param {ng.material.IToastService} $mdToast
   * @param {ng.IQService} $q
   * @param {import('../blocks/gtag').AnalyticsService} analytics
   * @param {import('../core').Config} config
   * @param {import('../core').ConnectionService} connection
   */
  constructor($window, $mdToast, $q, analytics, config, connection) {
    'ngInject'
    this.$window = $window
    this.$mdToast = $mdToast
    this.$q = $q
    this.analytics = analytics
    this.config = config
    this.connection = connection

    this.authenticating = false
    this.username = ''
    this.password = ''
  }

  $onInit() {
    if (this.error) {
      // @ts-ignore
      const textContent = Errors[this.error] || 'An unexpected error happenned'
      this.$mdToast.showError({ textContent, hideDelay: false })
    }
  }

  /**
   * Try to get_by_credentials user using given form credentials. If the credentials
   * are valids, a cookie is created containing the JWT and the user is
   * redirected to given url if it exists.
   */
  login() {
    this.authenticating = true
    this.connection
      .connect(this.username, this.password, false)
      .then(this._onAuthenticationCompleted.bind(this))
      .catch(this._onAuthenticationFailed.bind(this))
      .finally(this._clearForm.bind(this))
  }

  _redirect() {
    if (this.omniauthCallbackUrl) {
      this.$window.location.replace(`${this.omniauthCallbackUrl}?jwt=${this.connection.getToken()}`)
      return
    }
    this.$window.location.replace(this.redirectUrl || this.config.maxics_url)
  }

  _onAuthenticationCompleted() {
    return (
      this.analytics
        // @ts-ignore Actually we can send everything we want as event properties
        .sendEvent('login', { username: this.username, method: 'MaxICS' })
        .then(this._redirect.bind(this))
    )
  }

  /**
   *
   * @param {ng.IHttpResponse<any>} error
   * @returns
   */
  _onAuthenticationFailed(error) {
    if (error.status === 401) {
      this.$mdToast.showError('Invalid credentials')
      this.analytics.sendException('Login error', 'Invalid credentials', { fatal: false })
    } else {
      return this.$q.reject(error)
    }
  }

  _clearForm() {
    this.authenticating = false
    this.password = ''
    this.loginForm.$setPristine()
    this.loginForm.$setUntouched()
  }
}
