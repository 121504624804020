import angular from 'angular'
import defaultTo from 'lodash/defaultTo'

/**
 * @desc Service built on `gtag` to provide simple interface for tracking events, page view etc...
 *  This service is strongly inspired by `angulartics`. However, I did not provided settings for automatically
 *  send pageview/exception events on state change/unhandled exception. We prefer to let developers use our service
 *  when needed for us which provide more flexibility at the price of small amount of code to add
 */
export default class AnalyticsService {
  /**
   * @param {import('.').GtagWrapper} gtag
   * @param {ng.IWindowService} $window
   * @param {ng.IDocumentService} $document
   */
  constructor(gtag, $window, $document) {
    'ngInject'
    this.gtag = gtag
    this.$window = $window
    this.$document = $document

    this.trackedIds = new window.Set()
  }

  /**
   * Rewrite the property having given `source` name to given `dest` on provided object.
   * The value of `source` property is then assigned to `dest` property on given object
   * and `source` proprerty is then deleted.
   *
   * If given object has no `source` property, the function will have no effect
   * @param {any} obj
   * @param {string | number} source
   * @param {string | number} dest
   */
  _rewriteProperty(obj, source, dest) {
    if (source in obj) {
      obj[dest] = obj[source]
      delete obj[source]
    }
  }

  /**
   * Same as {@link _rewriteProperty} but for several source/target combinations.
   * Returned object is a copy of given one which is not modified.
   * @param {any} obj
   * @param {any} properties
   */
  _rewriteProperties(obj, properties) {
    obj = angular.copy(obj)
    angular.forEach(properties, (dest, source) => {
      this._rewriteProperty(obj, source, dest)
    })
    return obj
  }

  /**
   * Configure a new tracking ID.
   * Please note that this function DOES NOT send a page view command. It is automatically disabled so it is your responsability
   * to do it later on.
   * @param {string} trackingId
   * @param {Object} [params] Additional parameters. Some parameters have a special behavior and are described below.
   *  Other parameters will be used as default parameters for any next event sent using gtag.
   * @param {string} [params.groups] Associate given tracking ID to this group.
   * @param {boolean} [params.transport] Transport mechanism. Availables are `image`, `xhr` and `beacon`.
   *  See {@link https://developers.google.com/analytics/devguides/collection/gtagjs/sending-data/specify_different_transport_mechanisms transport mechanisms}
   */
  addTrackingId(trackingId, params) {
    params = params || {}
    /** @type {any} */
    let sendParams = { send_page_view: false }
    if (params.groups) sendParams.groups = params.groups
    if (params.transport) sendParams.transport_type = params.transport

    if (!this.trackedIds.has(trackingId)) {
      this.trackedIds.add(trackingId)
      this.gtag.config(trackingId, sendParams)
    }
    return false
  }

  /**
   * Enable or disable tracking.
   * @param {boolean} disabled
   * @param {string} [trackingId] Specific tracking ID to enable/disable. If not provided, any tracking ID added
   *  previously using {@link addTrackingId} will be affected
   */
  setTrackingDisabled(disabled, trackingId) {
    let ids = trackingId ? this.trackedIds : [trackingId]
    ids.forEach(() => {
      this.$window['ga-disable-' + this.trackedIds] = disabled
    })
  }

  /**
   * Set given key-value pairs so any next gtag calls will include by default these values.
   * @param {Object} values
   */
  setPersistentValues(values) {
    this.gtag.set(values)
  }

  /**
   * Send a page view event for given tracking ID.
   * @param {string} [trackingId] ID for which send the page view. If not provided, the function will send the page view to all
   *  IDs previously registered using {@link addTrackingId} function.
   * @param {object} [params] Page view parameters. Please note that parameters not described below will still be included in
   *  send params
   * @param {string} [params.title] Page title. Defaults to current document title. Shorthand for `page_title`
   * @param {string} [params.location] Page location. Defaults to current page url. Shorthand for `page_location`
   * @param {string} [params.path] Page path. Defaults to current page path. Shorthand for `page_path`
   */
  sendPageview(trackingId, params) {
    params = angular.copy(params) || {}
    if (!params.title) {
      params.title = this.$document.find('title').text()
    }
    if (!params.path) {
      params.path = this.$window.location.pathname
    }

    if (angular.isObject(trackingId)) {
      // @ts-ignore
      params = trackingId
      // @ts-ignore
      trackingId = null
    }

    // Send page view to given id if provided, else use already registered ones
    let targets = trackingId ? [trackingId] : this.trackedIds
    params = this._rewriteProperties(params, {
      title: 'page_title',
      location: 'page_location',
      path: 'page_path'
    })

    targets.forEach((id) => this.gtag.config(id, params))
  }

  /**
   * Send event to google analytics.
   * You can chain promises on returned one to ensure the event has been correctly sent (3seconds of waiting max)
   * before taking actions.
   * @param {string} action String that will appear as the event action in google analytics event reports
   * @param {Object} [params] Event parameters. Please note that parameters not described below will still be included in
   *  send params
   * @param {string} [params.category] String that will appear as the event category. Shorthand for `event_category`
   * @param {string} [params.label] String that will appear as the event label. Shorthand for `event_label`
   * @param {number} [params.value] Non negative integer which will appear as event value
   * @param {string} [params.to] Group to which event will be send. Shorthand for `send_to`
   * @param {string} [params.method] Event method
   * @param {boolean} [params.nonInteraction] Set event as a non-interaction event. Shorthand for `non_interaction`
   * @returns {ng.IPromise<boolean>} A promise which will resolve once event has been successfully sent to google analytics or
   *  after a maximum of 3seconds
   */
  sendEvent(action, params) {
    params = this._rewriteProperties(params, {
      category: 'event_category',
      label: 'event_label',
      nonInteraction: 'non_interaction',
      to: 'send_to'
    })
    // Resolve deferred promise once gtag event has been successfully sent
    return this.gtag.event(action, params)
  }

  sendUserTiming() {
    throw new Error('Not implemented')
  }

  /**
   * Send given `Error` in an exception event
   * @param {Error|string} error The error
   * @param {string} [cause] Additional information about error context
   * @param {Object} [options] Options
   * @param {boolean} [options.fatal=true] Whether this error is fatal or not
   * @param {string} [options.description] Error description. Defaults to given error `message` property.
   *  Please note that even if you provide a custom description, given `error` `message` will still available
   *  in his `stack` property which is registered as well in `stack` property of sent event.
   * @param {Object} [options.detail] A custom object that can be used to include additional informations about the error
   */
  sendException(error, cause, options) {
    options = options || {}
    return this.sendEvent('exception', {
      // @ts-ignore
      description: error instanceof Error ? error.message : error,
      stack: error instanceof Error ? error.stack : null,
      fatal: defaultTo(options.fatal, true),
      cause
    })
  }
}
