/**
 *
 * @param {ng.IHttpService} $http
 * @param {ng.cookies.ICookiesService} $cookies
 * @param {import('.').Config} config
 */
export default class ConnectionService {
  /**
   * @param {ng.IHttpService} $http
   * @param {ng.cookies.ICookiesService} $cookies
   * @param {import('.').Config} config
   */
  constructor($http, $cookies, config) {
    'ngInject'
    this.$http = $http
    this.$cookies = $cookies
    this.config = config
  }

  /**
   * @param {string} userName
   * @param {string} password
   * @param {boolean} withToken
   * @returns {ng.IPromise<any>}
   */
  connect(userName, password, withToken) {
    return this.$http.post('api/v1/connection', {
      user_name: userName,
      password: password,
      with_token: !!withToken
    })
  }

  /**
   *
   * @returns {ng.IPromise<any>}
   */
  disconnect() {
    return this.$http.delete('api/v1/connection')
  }

  /**
   * @returns {string}
   */
  getToken() {
    return this.$cookies.get(this.config.jwt_cookie_name)
  }
}
