import angular from 'angular'
import template from './lost-password.html'

export default {
  template,
  bindings: { token: '<' },
  get controller() {
    return LostPasswordController
  }
}

export class LostPasswordController {
  /** @type {string | null} */
  username = null
  /** @type {any} */
  recaptchaResponse = null
  /** @type {string | null} */
  captchaId = null
  sendingRequest = false
  recaptchaLoading = true
  /** @type {ng.IFormController} */
  // @ts-ignore Bounded from template at startup
  resetPasswordForm

  /**
   * @param {import('../core').UserService} userService
   * @param {import('../core').Config} config
   * @param {any} vcRecaptchaService
   * @param {import('@uirouter/angularjs').StateService} $state
   * @param {ng.material.IToastService} $mdToast
   * @param {ng.IScope} $scope
   * @param {ng.IWindowService} $window
   */
  constructor(userService, config, vcRecaptchaService, $state, $mdToast, $scope, $window) {
    'ngInject'
    this.userService = userService
    this.config = config
    this.vcRecaptchaService = vcRecaptchaService
    this.$state = $state
    this.$mdToast = $mdToast
    this.$scope = $scope
    this.$window = $window

    this.google_captcha_sitekey = config.google_captcha_sitekey
  }

  isCaptchaChecked() {
    return !Object.prototype.hasOwnProperty.call(this.resetPasswordForm.$error, 'recaptcha')
  }

  /**
   *
   * @param {string} id
   */
  setCaptchaId(id) {
    this.captchaId = id
    // Little hack to detect exactly when the recaptcha iframe is loaded
    // This way we can show up a spinner until recaptcha is loaded
    const captchaElem = this.$window.document.querySelectorAll('#recaptcha')
    angular.element(captchaElem).find('iframe').on('load', this.onRecaptchaRendered.bind(this))
  }

  onRecaptchaRendered() {
    this.$scope.$apply(() => (this.recaptchaLoading = false))
  }

  /**
   * @param {any} response
   */
  setCaptchaResponse(response) {
    this.recaptchaResponse = response
  }

  captchaExpired() {
    this.vcRecaptchaService.reload(this.captchaId)
    this.recaptchaResponse = null
  }

  /**
   * Ask for a password reset using the information from the form
   */
  resetPassword() {
    if (!this.username) {
      throw new Error("'username' must be specified to reset user's password")
    }

    this.sendingRequest = true
    return this.userService
      .resetPassword(this.username, this.recaptchaResponse)
      .then(this._onPasswordResetCompleted.bind(this))
      .catch((error) => {
        this.$mdToast.showError('An unexpected error happenned', error)
      })
      .finally(this._clearForm.bind(this))
  }

  _onPasswordResetCompleted() {
    this.$mdToast.showSuccess('A message has been sent to your email address to reset your password.')
    this.$state.go('login')
  }

  _clearForm() {
    this.vcRecaptchaService.reload(this.captchaId)
    this.resetPasswordForm.$setPristine()
    this.username = ''
    this.sendingRequest = false
  }
}
