import template from './shell.html'

export default {
  template,
  get controller() {
    return ShellController
  }
}

export class ShellController {
  /**
   * @param {ng.IWindowService} $window
   * @param {ng.IRootScopeService} $rootScope
   * @param {ng.ITimeoutService} $timeout
   * @param {import('../core').Config} config
   */
  constructor($window, $rootScope, $timeout, config) {
    'ngInject'
    this.$window = $window
    this.$rootScope = $rootScope
    this.$timeout = $timeout
    this.config = config

    this.graphicsUrl = config.graphics_url
  }

  $postLink() {
    this.$rootScope.pageTheme = this.config.app_theme
    // Force a 200ms delay so we can actually see the splash fading
    this.$timeout(() => (this.$rootScope.hideSplash = true), 200)
  }
}
