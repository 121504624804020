import template from './registration.html'

export default {
  template,
  get controller() {
    return RegistrationController
  }
}

export class RegistrationController {
  /** @type {ng.IFormController} */
  // @ts-ignore Bound on component startup
  registrationForm
  /** @type {string | null} */
  lastName = null
  /** @type {string | null} */
  firstName = null
  /** @type {string | null} */
  username = null

  /**
   *
   * @param {import('../core').UserService} userService
   * @param {ng.IWindowService} $window
   * @param {import('../core').Config} config
   * @param {any} vcRecaptchaService
   * @param {import('@uirouter/angularjs').StateService} $state
   */
  constructor(userService, $window, config, vcRecaptchaService, $state) {
    'ngInject'
    this.userService = userService
    this.$window = $window
    this.config = config
    this.vcRecaptchaService = vcRecaptchaService
    this.$state = $state

    this.recaptchaResponse = null
    this.captchaId = null
    this.google_captcha_sitekey = config.google_captcha_sitekey
  }

  isCaptchaChecked() {
    return !Object.prototype.hasOwnProperty.call(this.registrationForm.$error, 'recaptcha')
  }

  /**
   * @param {string} id
   */
  setCaptchaId(id) {
    this.captchaId = id
  }

  /**
   * @param {any} response
   */
  setCaptchaResponse(response) {
    this.recaptchaResponse = response
  }

  captchaExpired() {
    this.vcRecaptchaService.reload(this.captchaId)
    this.recaptchaResponse = null
  }

  /**
   * Register the user using the form
   */
  register() {
    return this.userService
      .register({
        user_name: this.username,
        first_name: this.firstName,
        last_name: this.lastName,
        recaptcha: this.recaptchaResponse
      })
      .then(this._cleanUser.bind(this))
      .then(this._redirect.bind(this))
      .finally(this._cleanForm.bind(this))
  }

  _redirect() {
    this.$state.go('login')
  }

  _cleanUser() {
    this.username = null
    this.firstName = null
    this.lastName = null
  }

  _cleanForm() {
    this.vcRecaptchaService.reload(this.captchaId)
    this.registrationForm.$setPristine()
    this.registrationForm.$setUntouched()
  }
}
