/**
 *
 * @param {import('@uirouter/angularjs').StateProvider} $stateProvider
 */
export default function lostPasswordRouteConfigBlock($stateProvider) {
  'ngInject'

  $stateProvider.state({
    url: '/lost-password',
    name: 'lostPassword',
    component: 'elLostPassword'
  })
}
