import angular from 'angular'

/**
 * @type {ng.IDirectiveFactory}
 * @param {import('.').AnalyticsService} analytics
 * @returns
 */
export default function miAnalyticsOn(analytics) {
  'ngInject'

  return {
    restrict: 'A',
    link(scope, iElement, iAttrs) {
      let eventName = iAttrs.miAnalyticsEvent
      let eventType = iAttrs.miAnalyticsOn || 'click'
      let eventParams = {}

      angular.forEach(iAttrs, handleAttr)
      iElement.on(eventType, eventHandler)

      function eventHandler() {
        if (iAttrs.miAnalyticsIf && !scope.$eval(iAttrs.miAnalyticsIf)) {
          return
        }

        // No need to trigger a digest cycle here
        let params = angular.extend({}, scope.$eval(iAttrs.miAnalyticsProperties), eventParams)
        analytics.sendEvent(eventName, params)
      }

      /**
       * @param {any} attr
       * @param {string} name
       */
      function handleAttr(attr, name) {
        if (isEventProperty(name)) {
          let propName = eventPropertyName(name)
          iAttrs.$observe(name, (value) => {
            // @ts-ignore
            eventParams[propName] = value
          })
        }
      }

      /**
       * @param {string} name
       */
      function isEventProperty(name) {
        return (
          name.startsWith('miAnalytics') &&
          !['miAnalyticsOn', 'miAnalyticsEvent', 'miAnalyticsProperties'].includes(name)
        )
      }

      /**
       * @param {string} name
       */
      function eventPropertyName(name) {
        name = name.replace('miAnalytics', '')
        return name.substring(0, 1).toLowerCase() + name.substring(1)
      }
    }
  }
}
