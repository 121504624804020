import angular from 'angular'

/**
 * @desc Service providing a simple interface for sending gtag commands.
 * gtag.js must be included manually as well as the following snippet at the end of the `<head>` tag
 * of your HTML page.
 * ```
 * <!-- Global site tag (gtag.js) - Google Analytics -->
 * <script async src="https://www.googletagmanager.com/gtag/js?id=GA_TRACKING_ID"></script>
 * You do not need to include the global tracking snippet as this service does it for you.
 * ```
 * @namespace
 * @memberof Blocks.Gtag
 * @ngInject
 */
export default class GtagWrapper {
  MAX_EVENT_WAIT_TIME_MS = 3 * 1000

  /**
   *
   * @param {ng.IWindowService} $window
   * @param {ng.ILogService} $log
   * @param {ng.ITimeoutService} $timeout
   * @param {ng.IQService} $q
   */
  constructor($window, $log, $timeout, $q) {
    'ngInject'
    this.$window = $window
    this.$log = $log
    this.$timeout = $timeout
    this.$q = $q

    this.$window.dataLayer = $window.dataLayer || []
  }

  _gtag() {
    this.$window.dataLayer.push(arguments)
  }

  /**
   * For more details on recommended gtag parameters, please have a look
   * at {@link https://developers.google.com/gtagjs/reference/parameter} official documentation.
   */

  /**
   * Initializes gtag tracking
   */
  init() {
    this._gtag('js', new Date())
  }

  /**
   * Add additional configuration information to targets.
   * @param {string} targetId Identifier that uniquely identifies the target for hits
   * @param {any} params Configuration parameters. Ignored if empty
   * @see https://developers.google.com/gtagjs/reference/api#config
   */
  config(targetId, params) {
    /** @type {Array<any>} */
    let args = ['config', targetId]
    if (params && Object.keys(params).length > 0) {
      // Includes params only if not empty
      args.push(params)
    }
    this._gtag(...args)
    this.$log.debug('gtag', ...args)
  }

  /**
   * Set values that persist across all the subsequent gtag() calls on the page
   * @param {Object} params Value parameters. Ignored if empty
   * @see https://developers.google.com/gtagjs/reference/api#set
   */
  set(params) {
    /** @type {Array<any>} */
    let args = ['set']
    if (params && Object.keys(params).length > 0) {
      // Includes params only if not empty
      args.push(params)
    }
    this._gtag(...args)
    this.$log.debug('gtag', ...args)
  }

  /**
   * Send event data.
   * @param {string} name Event name. See recommended events {@link https://developers.google.com/gtagjs/reference/event here}
   * @param {*} params Event parameters
   * @returns {ng.IPromise<boolean>} A promise which will resolve once event has been successfully sent to google analytics or
   * after a maximum of 3 seconds. This behavior prevent blocking chained promises for too long and prevents from rejecting
   * the whole promises chain if google analytics failed at sending the event as this is pretty "optionnal" and should
   * not change the user experience. The resolved value is a boolean set to true if google analytics succeeded, false otherwise (timeout)
   *
   * To get this behavior the event `event_callback` is overriden. For this reason, please don't use this parameter, instead,
   * chain promises
   * ```
   * gtag.event('login', {foo: "bar"}).then(() => doSomeStuff()))
   * ```
   * @see https://developers.google.com/gtagjs/reference/api#event
   */
  event(name, params) {
    let deferred = this.$q.defer()
    this.$timeout(() => deferred.resolve(false), this.MAX_EVENT_WAIT_TIME_MS)

    params = angular.extend({}, params, {
      event_callback: () => deferred.resolve(true)
    })

    this._gtag('event', name, params)
    this.$log.debug('gtag', name, params)

    return deferred.promise
  }
}
