import angular from 'angular'

export default class UserService {
  /**
   *
   * @param {ng.IHttpService} $http
   * @param {ng.IQService} $q
   * @param {import('.').Config} config
   */
  constructor($http, $q, config) {
    'ngInject'
    this.$http = $http
    this.$q = $q
    this.config = config

    this.baseConfig = {
      url: config.user_api_endpoint + '/v1'
    }
  }

  /**
   * @param {string} method
   * @param {string} url
   * @param {ng.IRequestShortcutConfig} [config]
   */
  _request(method, url, config) {
    const fullConfig = /** @type {ng.IRequestConfig} */ (angular.copy(config) || {})
    fullConfig.method = method
    fullConfig.url = this.baseConfig.url + url
    return this.$http(fullConfig).then((resp) => resp.data)
  }

  /**
   * Send user registration request
   * @param {Object} data registration content
   * @return {ng.IPromise<Object>} Registered user
   */
  register(data) {
    return this._request('POST', '/register/', { data })
  }

  /**
   * Send user account activation request
   * @param {string} token activation token
   * @param {string} password Account password
   * @return {ng.IPromise<Object>} Activated user
   */
  activate(token, password) {
    return this._request('POST', '/activate/', { data: { token, password } })
  }

  /**
   * Send reset password request
   * @param {string} userName Name of user for which reset password
   * @param {string} recaptcha Google recaptcha
   * @return {ng.IPromise<any>}
   */
  resetPassword(userName, recaptcha) {
    return this._request('POST', `/users/${userName}/password/`, { data: { recaptcha } })
  }

  /**
   * Check if user with given name exists
   * @param {string} userName Name of user to test
   * @return {ng.IPromise<undefined|ng.IHttpResponse<any>>} Promise which will fullfil if user exists or reject if it does not exists.
   *  If the request fails because of an unexpected error, the original server response will be included in rejected promise
   */
  exists(userName) {
    return this._request('GET', `/users/${userName}/exists/`).catch((error) =>
      this.$q.reject(error.status === 404 ? undefined : error)
    )
  }

  /**
   * Check if given registration token is valid
   * @param {string} token
   * @return {ng.IPromise<boolean>} Promise with a boolean indicating if given token is valid
   */
  checkToken(token) {
    return this._request('GET', `/token/${token}/`).then(() => !!token)
  }

  /**
   * @param {string} password
   * @returns {boolean}
   */
  isPasswordValid(password) {
    if (password) {
      if (password.length < 8) {
        return false
      }
      var numUpper = 0
      var numLower = 0
      var numNums = 0
      // Not using the special chars in the policy for now
      // var numSpecials = 0;
      for (var i = 0; i < password.length; i++) {
        if (password[i].match(/[0-9]/)) {
          numNums++
        } else if (password[i] === password[i].toUpperCase()) {
          numUpper++
        } else if (password[i] === password[i].toLowerCase()) {
          numLower++
        }
      }
      return numUpper >= 1 && numLower >= 1 && numNums >= 1
    }
    return false
  }

  /**
   *
   * @param {string} userName
   * @returns {ng.IPromise<any>}
   */
  isUserNameAvailable(userName) {
    return this.exists(userName).then(this.$q.reject, this.$q.resolve)
  }
}
