/**
 *
 * @param {import('@uirouter/angularjs').StateProvider} $stateProvider
 */
export function registrationRouteConfigBlock($stateProvider) {
  'ngInject'

  $stateProvider
    .state({
      url: '/register',
      name: 'registration',
      component: 'elRegistration'
    })
    .state({
      url: '/activate/{token}',
      name: 'activation',
      component: 'elActivation',
      resolve: {
        token: resolveToken,
        resetPassword: () => false
      }
    })
    .state({
      url: '/reset-password/{token}',
      name: 'resetPassword',
      component: 'elActivation',
      resolve: {
        token: resolveToken,
        resetPassword: () => true
      }
    })
}

/**
 *
 * @param {import('@uirouter/angularjs').Transition} $transition$
 * @param {import('../core').UserService} userService
 * @returns
 */
function resolveToken($transition$, userService) {
  'ngInject'
  /** @type {string} */
  const token = $transition$.params().token
  return userService.checkToken(token).then(() => token)
}

/**
 *
 * @param {import('@uirouter/angularjs').TransitionService} $transitions
 * @param {ng.material.IToastService} $mdToast
 */
export function registrationRouteRunBlock($transitions, $mdToast) {
  'ngInject'

  $transitions.onError({ to: 'resetPassword' }, _onInvalidToken)

  /**
   *
   * @param {import('@uirouter/angularjs').Transition} trans
   */
  function _onInvalidToken(trans) {
    const error = trans.error()
    if (error.detail.status === 404) {
      $mdToast.showError(
        'Requested URL is not valid. ' +
          'If you used an account activation or password reset link then your request expired'
      )
    }
  }
}
