import template from './activation.html'

export default {
  template,
  bindings: {
    token: '<',
    resetPassword: '<?'
  },
  get controller() {
    return ActivationController
  }
}

export class ActivationController {
  /** @type {string} */
  // @ts-ignore Initialized from component bindings
  token
  /** @type {boolean} */
  // @ts-ignore Initialized from component bindings
  resetPassword
  /** @type {ng.IFormController} */
  // @ts-ignore Bounded to template form
  passwordForm

  newPassword = ''
  newPasswordConfirm = ''
  sendingRequest = false

  /**
   * @param {import('../core').UserService} userService
   * @param {import('@uirouter/angularjs').StateService} $state
   * @param {ng.material.IToastService} $mdToast
   */
  constructor(userService, $state, $mdToast) {
    'ngInject'
    this.userService = userService
    this.$state = $state
    this.$mdToast = $mdToast
  }

  setPassword() {
    this.sendingRequest = true
    return this.userService
      .activate(this.token, this.newPassword)
      .then(this._onActivation.bind(this))
      .finally(this._clearForm.bind(this))
  }

  _onActivation() {
    if (this.resetPassword) {
      this.$mdToast.showSuccess('Your password has been successfully reset')
    } else {
      this.$mdToast.showSuccess('Your account has been successfully activated')
    }
    this.$state.go('login')
  }

  _clearForm() {
    this.sendingRequest = false
    this.newPassword = ''
    this.newPasswordConfirm = ''
    this.passwordForm.$setPristine()
    this.passwordForm.$setUntouched()
  }
}
