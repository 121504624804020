import angular from 'angular'
import coreModule from '../core'
import equalsDirective from './equals.directive'
import passwordPolicyDirective from './password-policy.directive'
import verifyEmailAvailabilityDirective from './verify-email-availability.directive'
import verifyEmailExistsDirective from './verify-email-exists.directive'

export default angular
  .module('app.widgets', [coreModule])
  .directive('elEquals', equalsDirective)
  .directive('elPasswordPolicy', passwordPolicyDirective)
  .directive('elVerifyEmailAvailability', verifyEmailAvailabilityDirective)
  .directive('elVerifyEmailExists', verifyEmailExistsDirective)
