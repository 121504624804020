import angular from 'angular'
import coreModule from '../core'
import activationComponent from './activation.component'
import registrationComponent from './registration.component'
import { registrationRouteConfigBlock, registrationRouteRunBlock } from './registration.route'

export default angular
  .module('app.registration', [coreModule])
  .config(registrationRouteConfigBlock)
  .run(registrationRouteRunBlock)
  .component('elActivation', activationComponent)
  .component('elRegistration', registrationComponent)
