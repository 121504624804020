import uiRouter from '@uirouter/angularjs'
import angular from 'angular'
import ngAnimate from 'angular-animate'
import ngCookies from 'angular-cookies'
import ngMaterial from 'angular-material'
import 'angular-material/angular-material.css'
import ngMessages from 'angular-messages'
import vcRecaptcha from 'angular-recaptcha'
import gtagModule from '../blocks/gtag'
import { configBlock, runBlock } from './config'
import ConnectionService from './connection.service'
import { routeConfigBlock, routeRunBlock } from './core.route'
import $exceptionHandlerDecorator from './exception-handler.decorator'
import UserService from './user.service'

export default angular
  .module('app.core', [
    /* Angular modules */
    ngMaterial,
    ngMessages,
    ngAnimate,
    ngCookies,
    /* Cross-app modules */
    gtagModule,
    /* Third party app modules */
    vcRecaptcha,
    uiRouter
  ])
  .config(configBlock)
  .config(routeConfigBlock)
  .run(runBlock)
  .run(routeRunBlock)
  .decorator('$exceptionHandler', $exceptionHandlerDecorator)
  .service('userService', UserService)
  .service('connection', ConnectionService)
