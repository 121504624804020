import angular from 'angular'
import coreModule from './core'
import layoutModule from './layout'
import loginModule from './login'
import lostPasswordModule from './lost-password'
import registrationModule from './registration'
import widgetsModule from './widgets'

export default angular.module('app', [
  /* Shared modules */
  coreModule,
  widgetsModule,
  layoutModule,
  /* Features area */
  loginModule,
  registrationModule,
  lostPasswordModule
])
